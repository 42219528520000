.cfs-master-bl-detail {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  &__topbar {
    display: flex;
    width: 100%;
    height: 36px;
    justify-content: space-between;
    margin-bottom: 24px;

    &--left {
      display: flex;
      align-items: center;
      font-weight: 500;

      label {
        font-size: 24px;
      }

      span {
        font-size: 16px;
        color: #FF0000;

        &:before {
          content: '';
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background: #FF0000;
          margin: 0 8px 0 14px;
          vertical-align: middle;
        }
      }
    }

    &--right {
      display: flex;
    }
  }

  &__detail {
    width: 100%;
    flex-shrink: 0;
    border-radius: 4px;
    background: #FFF;
    padding: 36px 28px;

    &--info {
      display: flex;
      flex-wrap: wrap;
      column-gap: 68px;
    }

    &--info-block {
      width: 320px;
      font-weight: 400;
      font-size: 16px;
    }
  }

  &__list {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: #FFF;
    padding: 36px 28px;
    margin-top: 24px;

    &--topbar {
      display: flex;
      width: 100%;
      height: 36px;
      justify-content: space-between;
      margin-bottom: 24px;
    }

    &--content {
      width: 100%;
      height: 100%;
    }
  }

  &__btn {
    display: flex;
    width: 120px;
    background: #3F6AE2;
    color: #FFF;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
  }
}

input[disabled] {
  color: #000 !important;
  background: #DFDFDF !important;
}

.ant-picker-disabled {
  color: #000 !important;
  background: #DFDFDF !important;
}

.ant-select-disabled {
  .ant-select-selector {
    color: #000 !important;
    background: #DFDFDF !important;
  }
}

.ant-input-number-affix-wrapper-disabled {
  color: #000 !important;
  background: #DFDFDF !important;
}

.ant-input-number-disabled {
  color: #000 !important;
  background: #DFDFDF !important;
}
