@left-width: 75%;
@right-width: 25%;
@row-height: 38px;

.fee-form {
  width: 100%;

  &__datepicker {
    width: 100%;
    margin-bottom: 18px;

    &--label {
      width: 100%;
      font-size: 14px;
      margin-bottom: 8px;
    }
  }

  &__row {
    display: flex;
    height: @row-height;
    line-height: @row-height;

    &.label {
      background: #162E6F;
      color: #FFF;
    }

    &.content {
      background: #F3F5F7;
      color: #000;

      &:nth-child(2n) {
        background: #E8E8E8;
      }
    }

    &--left {
      width: @left-width;
      height: @row-height;
      border-right: 1px solid #BAC5EC;
      padding: 0 12px;
      box-sizing: border-box;
    }

    &--right {
      width: @right-width;
      height: @row-height;
      line-height: @row-height;
      padding: 0 12px;
      box-sizing: border-box;
      text-align: right;

      .currency-input {
        vertical-align: super;
        padding-right: 2px;

        input {
          text-align: right !important;
        }
      }
    }
  }

  &__block {
    width: 100%;

    &.btns {
      display: flex;
      justify-content: flex-end;
      margin-top: 18px;
      gap: 18px;
    }

    &--btn {
      width: 120px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;

      &.back {
        color: #6D7584;
        border: 1px solid #6D7584;
      }

      &.pay {
        color: #FFF;
        background: #2C6CE7;
      }
    }
  }
}
